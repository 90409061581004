import beranda
<template>
  <div>
    <!-- <NuxtRouteAnnouncer />
    <NuxtWelcome /> -->
    <nuxtLayout>
      <NuxtPage />
    </nuxtLayout>
  </div>
</template>
