export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"href":"https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css","rel":"stylesheet"},{"href":"/assets/fonts/remixicon/remixicon.css","rel":"stylesheet"},{"href":"/assets/vendor/libs/node-waves/node-waves.css","rel":"stylesheet"},{"href":"/assets/vendor/css/core.css","rel":"stylesheet"},{"href":"/assets/vendor/css/theme-default.css","rel":"stylesheet"},{"href":"/assets/css/demo.css","rel":"stylesheet"},{"href":"/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css","rel":"stylesheet"},{"href":"/assets/vendor/css/pages/page-auth.css","rel":"stylesheet"},{"href":"/assets/vendor/css/pages/page-misc.css","rel":"stylesheet"}],"style":[],"script":[{"src":"/assets/vendor/js/helpers.js"},{"src":"/assets/js/config.js"},{"src":"/assets/vendor/libs/jquery/jquery.js"},{"src":"/assets/vendor/libs/popper/popper.js"},{"src":"/assets/vendor/js/bootstrap.js"},{"src":"/assets/vendor/libs/node-waves/node-waves.js"},{"src":"/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js"},{"src":"/assets/vendor/js/menu.js"},{"src":"/assets/js/main.js"},{"type":"module","src":"https://buttons.github.io/buttons.js"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"