
import * as ipxStaticRuntime$2axmQoFMDx from 'F:/laragon/www/gombloh/admin/node_modules/@nuxt/image/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipxStatic",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "png",
    "webp"
  ],
  "quality": 80
}

imageOptions.providers = {
  ['ipxStatic']: { provider: ipxStaticRuntime$2axmQoFMDx, defaults: {} }
}
        