import { default as _91id_93G0JyT7oSGDMeta } from "F:/laragon/www/gombloh/admin/pages/albums/[id].vue?macro=true";
import { default as formalbumsTcRFxIHfnQMeta } from "F:/laragon/www/gombloh/admin/pages/albums/formalbums.vue?macro=true";
import { default as indexMSEC5lxOgbMeta } from "F:/laragon/www/gombloh/admin/pages/albums/index.vue?macro=true";
import { default as _91id_93SLwbla7yJ6Meta } from "F:/laragon/www/gombloh/admin/pages/beritas/[id].vue?macro=true";
import { default as formberitashKTuhknOeYMeta } from "F:/laragon/www/gombloh/admin/pages/beritas/formberitas.vue?macro=true";
import { default as indexolPk18T2M9Meta } from "F:/laragon/www/gombloh/admin/pages/beritas/index.vue?macro=true";
import { default as dashboardBnkoHUI2C3Meta } from "F:/laragon/www/gombloh/admin/pages/dashboard.vue?macro=true";
import { default as _91id_93pqIys4yz1pMeta } from "F:/laragon/www/gombloh/admin/pages/galeris/[id].vue?macro=true";
import { default as formgaleriss4ZYRDI5jpMeta } from "F:/laragon/www/gombloh/admin/pages/galeris/formgaleris.vue?macro=true";
import { default as indexJIa1JKA5YqMeta } from "F:/laragon/www/gombloh/admin/pages/galeris/index.vue?macro=true";
import { default as _91id_93GN5LtcZhpnMeta } from "F:/laragon/www/gombloh/admin/pages/halaman-statis/[id].vue?macro=true";
import { default as formhalamanstatisElg87GI7tZMeta } from "F:/laragon/www/gombloh/admin/pages/halaman-statis/formhalamanstatis.vue?macro=true";
import { default as index82MD4ALSzVMeta } from "F:/laragon/www/gombloh/admin/pages/halaman-statis/index.vue?macro=true";
import { default as _91id_93daawNB2IQ6Meta } from "F:/laragon/www/gombloh/admin/pages/identitas/[id].vue?macro=true";
import { default as formidentitasIZDi3cBs76Meta } from "F:/laragon/www/gombloh/admin/pages/identitas/formidentitas.vue?macro=true";
import { default as indexEZatfS4MupMeta } from "F:/laragon/www/gombloh/admin/pages/identitas/index.vue?macro=true";
import { default as indexEAsPTM5OKwMeta } from "F:/laragon/www/gombloh/admin/pages/index.vue?macro=true";
import { default as _91id_93r4ojOpybGJMeta } from "F:/laragon/www/gombloh/admin/pages/kategori-resumes/[id].vue?macro=true";
import { default as formkategoriresumes0XvH1fpUsaMeta } from "F:/laragon/www/gombloh/admin/pages/kategori-resumes/formkategoriresumes.vue?macro=true";
import { default as indexwhIvqrIHjgMeta } from "F:/laragon/www/gombloh/admin/pages/kategori-resumes/index.vue?macro=true";
import { default as _91id_93zNnZwSoia8Meta } from "F:/laragon/www/gombloh/admin/pages/kategoris/[id].vue?macro=true";
import { default as formkategorisha8gRbWG0fMeta } from "F:/laragon/www/gombloh/admin/pages/kategoris/formkategoris.vue?macro=true";
import { default as indextr12l8oWWjMeta } from "F:/laragon/www/gombloh/admin/pages/kategoris/index.vue?macro=true";
import { default as _91id_93a4S2KRPWByMeta } from "F:/laragon/www/gombloh/admin/pages/portofolios/[id].vue?macro=true";
import { default as formportofoliosIrrpq1ERj5Meta } from "F:/laragon/www/gombloh/admin/pages/portofolios/formportofolios.vue?macro=true";
import { default as index8MGuEYRhUmMeta } from "F:/laragon/www/gombloh/admin/pages/portofolios/index.vue?macro=true";
import { default as _91id_93RzyBgust85Meta } from "F:/laragon/www/gombloh/admin/pages/profiles/[id].vue?macro=true";
import { default as formprofilesj5aIfq6W3TMeta } from "F:/laragon/www/gombloh/admin/pages/profiles/formprofiles.vue?macro=true";
import { default as indexyiIGJwbTYqMeta } from "F:/laragon/www/gombloh/admin/pages/profiles/index.vue?macro=true";
import { default as _91id_93elODG5ki9VMeta } from "F:/laragon/www/gombloh/admin/pages/resumes/[id].vue?macro=true";
import { default as formresumesujF6A2rmMjMeta } from "F:/laragon/www/gombloh/admin/pages/resumes/formresumes.vue?macro=true";
import { default as indexMxNtjxZ5ybMeta } from "F:/laragon/www/gombloh/admin/pages/resumes/index.vue?macro=true";
import { default as _91id_93gNsX3kR0oSMeta } from "F:/laragon/www/gombloh/admin/pages/testimonials/[id].vue?macro=true";
import { default as formtestimonials4hHrdW3RNaMeta } from "F:/laragon/www/gombloh/admin/pages/testimonials/formtestimonials.vue?macro=true";
import { default as indexKyLouza6xcMeta } from "F:/laragon/www/gombloh/admin/pages/testimonials/index.vue?macro=true";
import { default as _91id_93HoKmBJxlBBMeta } from "F:/laragon/www/gombloh/admin/pages/users/[id].vue?macro=true";
import { default as formusers6XYBQZngWHMeta } from "F:/laragon/www/gombloh/admin/pages/users/formusers.vue?macro=true";
import { default as index0V5k7vxk5SMeta } from "F:/laragon/www/gombloh/admin/pages/users/index.vue?macro=true";
export default [
  {
    name: "albums-id",
    path: "/albums/:id()",
    component: () => import("F:/laragon/www/gombloh/admin/pages/albums/[id].vue")
  },
  {
    name: "albums-formalbums",
    path: "/albums/formalbums",
    component: () => import("F:/laragon/www/gombloh/admin/pages/albums/formalbums.vue")
  },
  {
    name: "albums",
    path: "/albums",
    component: () => import("F:/laragon/www/gombloh/admin/pages/albums/index.vue")
  },
  {
    name: "beritas-id",
    path: "/beritas/:id()",
    component: () => import("F:/laragon/www/gombloh/admin/pages/beritas/[id].vue")
  },
  {
    name: "beritas-formberitas",
    path: "/beritas/formberitas",
    component: () => import("F:/laragon/www/gombloh/admin/pages/beritas/formberitas.vue")
  },
  {
    name: "beritas",
    path: "/beritas",
    component: () => import("F:/laragon/www/gombloh/admin/pages/beritas/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("F:/laragon/www/gombloh/admin/pages/dashboard.vue")
  },
  {
    name: "galeris-id",
    path: "/galeris/:id()",
    component: () => import("F:/laragon/www/gombloh/admin/pages/galeris/[id].vue")
  },
  {
    name: "galeris-formgaleris",
    path: "/galeris/formgaleris",
    component: () => import("F:/laragon/www/gombloh/admin/pages/galeris/formgaleris.vue")
  },
  {
    name: "galeris",
    path: "/galeris",
    component: () => import("F:/laragon/www/gombloh/admin/pages/galeris/index.vue")
  },
  {
    name: "halaman-statis-id",
    path: "/halaman-statis/:id()",
    component: () => import("F:/laragon/www/gombloh/admin/pages/halaman-statis/[id].vue")
  },
  {
    name: "halaman-statis-formhalamanstatis",
    path: "/halaman-statis/formhalamanstatis",
    component: () => import("F:/laragon/www/gombloh/admin/pages/halaman-statis/formhalamanstatis.vue")
  },
  {
    name: "halaman-statis",
    path: "/halaman-statis",
    component: () => import("F:/laragon/www/gombloh/admin/pages/halaman-statis/index.vue")
  },
  {
    name: "identitas-id",
    path: "/identitas/:id()",
    component: () => import("F:/laragon/www/gombloh/admin/pages/identitas/[id].vue")
  },
  {
    name: "identitas-formidentitas",
    path: "/identitas/formidentitas",
    component: () => import("F:/laragon/www/gombloh/admin/pages/identitas/formidentitas.vue")
  },
  {
    name: "identitas",
    path: "/identitas",
    component: () => import("F:/laragon/www/gombloh/admin/pages/identitas/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("F:/laragon/www/gombloh/admin/pages/index.vue")
  },
  {
    name: "kategori-resumes-id",
    path: "/kategori-resumes/:id()",
    component: () => import("F:/laragon/www/gombloh/admin/pages/kategori-resumes/[id].vue")
  },
  {
    name: "kategori-resumes-formkategoriresumes",
    path: "/kategori-resumes/formkategoriresumes",
    component: () => import("F:/laragon/www/gombloh/admin/pages/kategori-resumes/formkategoriresumes.vue")
  },
  {
    name: "kategori-resumes",
    path: "/kategori-resumes",
    component: () => import("F:/laragon/www/gombloh/admin/pages/kategori-resumes/index.vue")
  },
  {
    name: "kategoris-id",
    path: "/kategoris/:id()",
    component: () => import("F:/laragon/www/gombloh/admin/pages/kategoris/[id].vue")
  },
  {
    name: "kategoris-formkategoris",
    path: "/kategoris/formkategoris",
    component: () => import("F:/laragon/www/gombloh/admin/pages/kategoris/formkategoris.vue")
  },
  {
    name: "kategoris",
    path: "/kategoris",
    component: () => import("F:/laragon/www/gombloh/admin/pages/kategoris/index.vue")
  },
  {
    name: "portofolios-id",
    path: "/portofolios/:id()",
    component: () => import("F:/laragon/www/gombloh/admin/pages/portofolios/[id].vue")
  },
  {
    name: "portofolios-formportofolios",
    path: "/portofolios/formportofolios",
    component: () => import("F:/laragon/www/gombloh/admin/pages/portofolios/formportofolios.vue")
  },
  {
    name: "portofolios",
    path: "/portofolios",
    component: () => import("F:/laragon/www/gombloh/admin/pages/portofolios/index.vue")
  },
  {
    name: "profiles-id",
    path: "/profiles/:id()",
    component: () => import("F:/laragon/www/gombloh/admin/pages/profiles/[id].vue")
  },
  {
    name: "profiles-formprofiles",
    path: "/profiles/formprofiles",
    component: () => import("F:/laragon/www/gombloh/admin/pages/profiles/formprofiles.vue")
  },
  {
    name: "profiles",
    path: "/profiles",
    component: () => import("F:/laragon/www/gombloh/admin/pages/profiles/index.vue")
  },
  {
    name: "resumes-id",
    path: "/resumes/:id()",
    component: () => import("F:/laragon/www/gombloh/admin/pages/resumes/[id].vue")
  },
  {
    name: "resumes-formresumes",
    path: "/resumes/formresumes",
    component: () => import("F:/laragon/www/gombloh/admin/pages/resumes/formresumes.vue")
  },
  {
    name: "resumes",
    path: "/resumes",
    component: () => import("F:/laragon/www/gombloh/admin/pages/resumes/index.vue")
  },
  {
    name: "testimonials-id",
    path: "/testimonials/:id()",
    component: () => import("F:/laragon/www/gombloh/admin/pages/testimonials/[id].vue")
  },
  {
    name: "testimonials-formtestimonials",
    path: "/testimonials/formtestimonials",
    component: () => import("F:/laragon/www/gombloh/admin/pages/testimonials/formtestimonials.vue")
  },
  {
    name: "testimonials",
    path: "/testimonials",
    component: () => import("F:/laragon/www/gombloh/admin/pages/testimonials/index.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    component: () => import("F:/laragon/www/gombloh/admin/pages/users/[id].vue")
  },
  {
    name: "users-formusers",
    path: "/users/formusers",
    component: () => import("F:/laragon/www/gombloh/admin/pages/users/formusers.vue")
  },
  {
    name: "users",
    path: "/users",
    component: () => import("F:/laragon/www/gombloh/admin/pages/users/index.vue")
  }
]